
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/grids.scss';
@import '../../../style/font-family.scss';
@import '../../../style/globalColorVars.scss';
@import '~bootstrap/scss/mixins';

.item-meta {
  position: relative;
  display: block;
  width: 100%;

  a {
    float: right;
    color: $link-primary;
    font-size: 14px;

    svg {
      height: 14px;
      color: $link-primary;
    }
  }

  &.series {
    a {
      float: left;
    }
  }
}

.body-header {
  position: relative;
  display: flex;
  height: 28px;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  .series-count {
    @include roboto-light;
  }

  .body-comp-logo {
    position: absolute;
    right: 0;
    margin-right: 0;
    width: 70px;
    height: 28px;

    img {
      object-fit: cover;
    }
  }
}

.main-body {
  .item-title,
  .card-description {
    margin-bottom: 15px;
  }
}

.series-count {
  font-size: 14px;

  svg {
    margin-right: 6px;
	color:$bt-light-grey;
  }
}

.vertical-list {
  .search-summit-item {
    .item-title {
      margin-bottom: 0;
    }

    .item-meta {
      margin-top: 15px;
    }
  }

  .presenter,
  .img-wrap {
    img {
      background-color: transparent !important;
    }
  }

  .presented-by {
    align-self: center;
    margin-right: 10px;
  }
}

// Revert the height auto to resolved the mixed ratios images for series items.
.series-item,
.related-series-card {
  .img-wrap {
    img {
      height: revert-layer;
      object-fit: cover;
    }
  }
}
